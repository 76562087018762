import { Link } from "react-router-dom";

function ConfPolContent({ siteUrl, email }) {
  return (
    <article className="confPol-page__container-article">
      <h2>Mentions légales</h2>
      <p>
        En conformité avec les articles 6-III et 19 de la Loi n° 2004-575 du 21
        juin 2004 pour la confiance dans l'économie numérique (L.C.E.N.), nous
        portons à la connaissance des utilisateurs du site internet Le site web{" "}
        <Link to="/">{siteUrl}</Link> les informations suivantes :
      </p>
      <h3>1. Identification légale</h3>
      <p>
        Le site <Link to="/">{siteUrl}</Link> est la propriété exclusive de SAS
        JPFT (Maëly’s Café).
      </p>
      <h4>SAS JPFT (Maëly’s Café) :</h4>
      <ul>
        <li>
          <span>Téléphone :</span> 09 50 80 87 15
        </li>
        <li>
          <span>Adresse :</span> 286 chemin de Bassaquet, 83140
          Six-Fours-les-Plages, France
        </li>
        <li>
          <span>Numéro SIREN :</span> 938 231 990
        </li>
        <li>
          <span>Numéro de TVA :</span> FR34938231990
        </li>
        <li>
          <span>Adresse email : </span>
          <a href={`mailto:${email}`}>contact@maelys-cafe.fr</a>
        </li>
      </ul>
      <h4>
        <span>Créateur du site :</span> Benjamin GUILLEMOT
      </h4>
      <ul>
        <li>
          <span>Responsable de publication :</span> Benjamin GUILLEMOT
        </li>
        <li>
          <span>Contact responsable de publication :</span>{" "}
          <a href="mailto:ben.guillemot1@gmail.com">ben.guillemot1@gmail.com</a>
        </li>
      </ul>
      <h4>Hébergeur :</h4>
      <ul>
        <li>
          <span>Ionos</span>
        </li>
        <li>
          <span>RCS :</span> Sarreguemines B 431 303 775
        </li>
        <li>
          <span>Code APE :</span> 6311Z
        </li>
        <li>
          <span>Numéro de TVA :</span> FR13431303775
        </li>
        <li>
          <span>Adresse :</span> 7 place de la Gare, 57200 Sarreguemines, France
        </li>
      </ul>
      <h3>2. Présentation et principes</h3>
      <p>
        Tout internaute utilisant le site <Link to="/">{siteUrl}</Link> est
        considéré comme un utilisateur du site. Le site{" "}
        <Link to="/">{siteUrl}</Link> fournit des informations et services
        accessibles en ligne. Les utilisateurs doivent rester courtois et agir
        de bonne foi envers les autres utilisateurs et le concepteur du site.
        Benjamin GUILLEMOT met à jour régulièrement le site pour offrir des
        informations aussi précises que possible. Toutefois, il ne garantit pas
        l'exactitude, l'exhaustivité ou l'actualité des informations diffusées.
        L'utilisateur est donc responsable de l'usage qu'il fait de ces
        informations.
      </p>
      <h3>3. Accessibilité</h3>
      <p>
        Le site <Link to="/">{siteUrl}</Link> est accessible 24h/24 et 7j/7,
        sauf interruption pour maintenance ou en cas de force majeure. En cas
        d’interruption, le site s’efforcera d’en informer les utilisateurs à
        l’avance. Le Maëly’s Café ne saurait être tenu responsable des
        interruptions de service.
      </p>
      <h3>4. Propriété intellectuelle</h3>
      <p>
        Le Maëly's Café et Benjamin GUILLEMOT détiennent l'intégralité des
        droits de propriété intellectuelle sur les éléments accessibles sur le
        site (textes, graphismes, logos, logiciels, etc.). Toute reproduction,
        représentation, modification ou adaptation, même partielle, du site ou
        de l’un de ses éléments, sans autorisation préalable écrite de Benjamin
        GUILLEMOT, est strictement interdite. Toute infraction sera considérée
        comme une contrefaçon et poursuivie conformément au Code de la propriété
        intellectuelle (articles L.335-2 et suivants).
      </p>
      <h3>5. Liens hypertextes et cookies</h3>
      <p>
        Le site <Link to="/">{siteUrl}</Link> contient des liens hypertextes
        vers des sites partenaires ou d’information. Benjamin GUILLEMOT décline
        toute responsabilité quant au contenu de ces sites externes. Lors de la
        navigation sur <Link to="/">{siteUrl}</Link>, des cookies peuvent
        s’installer automatiquement sur l’ordinateur de l’utilisateur. Ces
        cookies collectent des informations sur la navigation, mais ne
        permettent pas d’identifier personnellement l’utilisateur. L’utilisateur
        peut configurer son navigateur pour bloquer les cookies. Cependant, le
        refus de cookies peut limiter l’accès à certains services.
      </p>
      <h3>6. Protection des données personnelles</h3>
      <p>
        En France, les données personnelles sont protégées par la Loi n° 78-17
        du 6 janvier 1978 (modifiée) et par le Règlement Général sur la
        Protection des Données (RGPD). Le site <Link to="/">{siteUrl}</Link>{" "}
        collecte des données personnelles uniquement pour répondre aux services
        proposés. L’utilisateur fournit ses informations en connaissance de
        cause, notamment lorsqu’il les saisit lui-même. Conformément aux
        articles 38 et suivants de la loi précitée, chaque utilisateur dispose
        d’un droit d’accès, de rectification, de suppression et d’opposition
        concernant ses données personnelles. Pour exercer ce droit, envoyez
        votre demande par email à{" "}
        <a href="mailto:ben.guillemot1@gmail.com">Benjamin Guillemot</a> ou par
        courrier signé, accompagné d’une copie d’une pièce d’identité, à
        l’adresse suivante : 286 chemin de Bassaquet, 83140
        Six-Fours-les-Plages, France. Aucune information personnelle n’est
        publiée, échangée ou vendue à des tiers sans le consentement de
        l’utilisateur. Le site <Link to="/">{siteUrl}</Link> est conforme au
        RGPD.
      </p>
    </article>
  );
}

export default ConfPolContent;
