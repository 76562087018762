import { Box, Typography } from "@mui/material";
import visa from "../../../../Assets/visa.svg";
import mastercard from "../../../../Assets/mastercard-2.svg";
import useIsMobile from "../../../../hooks/useIsMobile";

const CAFE_INFO = {
  name: "Maëly's Cafe",
  openingDays: "Ouvert de 19h à 01h du Lundi au Samedi",
  specialties: ["et veilles de jours fériés", "Concerts tous les week-ends"],
  phone: "09 50 80 87 15",
  mapUrl:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2912.093648311182!2d5.890198415916959!3d43.12355887914292!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12c9050d3217b7b1%3A0x322ca2fdc4df8b82!2zTWHDq2x5cyBjYWbDqQ!5e0!3m2!1sfr!2sfr!4v1651473313702!5m2!1sfr!2sfr",
};

const PAYMENT_METHODS = [
  { name: "Visa", logo: visa },
  { name: "Mastercard", logo: mastercard },
];

const styles = {
  container: (isMobile) => ({
    textAlign: "center",
    color: "#fffde7",
    padding: isMobile ? "0.5rem 0" : 0,
  }),
  paymentMethods: {
    display: "flex",
    gap: 5,
    justifyContent: "center",
    alignItems: "center",
    flexWrap: "wrap",
    margin: "1rem 0",
    "& img": {
      width: "3rem",
    },
  },
  phoneNumber: {
    "&:hover": {
      textShadow:
        "0px 0px 1px rgba(255,253,231,1),0px 0px 15px rgba(255, 253, 231, 0.43),0px 0px 51px rgba(255,253,231,0.2)",
    },
  },
};

function PaymentMethods() {
  return (
    <Box>
      <Typography>Moyens de paiement acceptés :</Typography>
      <Box sx={styles.paymentMethods}>
        {PAYMENT_METHODS.map((method) => (
          <img
            key={method.name}
            src={method.logo}
            alt={`Logo ${method.name}`}
          />
        ))}
      </Box>
    </Box>
  );
}

function ContactInfo({ phone }) {
  return (
    <a href={`tel:+33${phone.replace(/\s/g, "")}`}>
      <Typography variant="body2" sx={styles.phoneNumber}>
        Tel : {phone}
      </Typography>
    </a>
  );
}
function Infos() {
  const isMobile = useIsMobile();

  return (
    <Box sx={styles.container(isMobile)}>
      <Typography variant="h1">{CAFE_INFO.name}</Typography>
      <Typography variant="body2" sx={{ textTransform: "uppercase" }}>
        {CAFE_INFO.openingDays}
      </Typography>
      {CAFE_INFO.specialties.map((specialty, index) => (
        <Typography key={index} sx={{ margin: "1rem" }}>
          {specialty}
        </Typography>
      ))}
      <PaymentMethods />
      <ContactInfo phone={CAFE_INFO.phone} />
      <Box sx={{ margin: "1rem" }}>
        <iframe
          title="Carte Google Maps"
          src={CAFE_INFO.mapUrl}
          width="100%"
          height="auto"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </Box>
    </Box>
  );
}

export default Infos;
