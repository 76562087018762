import { Typography, Box } from "@mui/material";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <Box
      sx={{
        borderTop: "1px solid #fffde7",
        width: "100%",
        padding: "1rem 0.5rem",
      }}
      className="footer"
    >
      <Typography
        variant="subtitle2"
        sx={{
          "&:hover": {
            textShadow:
              "0px 0px 1px rgba(255,253,231,1),0px 0px 15px rgba(255, 253, 231, 0.43),0px 0px 51px rgba(255,253,231,0.2)",
          },
        }}
      >
        <Link to="/mentions-legales">Mentions légales</Link>
      </Typography>
    </Box>
  );
}
export default Footer;
