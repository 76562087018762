import { Link } from "react-router-dom";
import ConfPolSEOMetadata from "./ConfPolSEOMetadata";
import ConfPolContent from "./ConfPolContent";
import Main from "../../common/Main/Main";
import "./confPol.scss";

const SITE_URL = "maelys-cafe.fr";
const EMAIL = "contact@maelys-cafe.fr";
function ConfPol() {
  return (
    <>
      <ConfPolSEOMetadata siteUrl={SITE_URL} />
      <Main>
        <div className="confPol-page">
          <div className="confPol-page__container">
            <ConfPolContent siteUrl={SITE_URL} email={EMAIL} />
          </div>
        </div>
      </Main>
    </>
  );
}

export default ConfPol;
