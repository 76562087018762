import {
  Box,
  CardContent,
  CardMedia,
  Typography,
  styled,
  Button,
} from "@mui/material";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { getFrFormat, isToLate } from "../../../utils/dates/dates";
import BookingForm from "../BookingForm/CreateResa";

const boxStyles = {
  position: "relative",
  flexDirection: "column",
  padding: "0.5rem",
  width: "30%",
  color: "#fffde7",
  textAlign: "center",
  background:
    "linear-gradient(#0e0e0e 65%, #030303 66%) top 0.03em center / 100% 0.3em repeat-y;",
  margin: "1rem auto",
  border: `8px solid #fffde7`,
  borderRadius: "5px",
  boxShadow:
    "0px 0px 6px rgba(255,253,231,0.6), inset 0px 0px 6px rgba(255,253,231,0.6), 0px 0px 16px rgba(255,253,231,0.4), inset 0px 0px 16px rgba(255,253,231,0.4), 0px 0px 32px rgba(255,253,231,0.2), inset 0px 0px 32px rgba(255,253,231,0.2)",
};

const cardContentStyles = {
  display: "flex",
  "&:before": {
    content: `""`,
    display: "block",
    width: "60px",
    height: "20px",
    margin: "auto",
    background: " rgba(255, 255, 200, 0.6)",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.4)",
    position: "absolute",
    top: "15px",
    left: "5px",
    transform: "rotate(-45deg)",
  },
  "&:after": {
    content: `""`,
    display: "block",
    width: "64px",
    height: "20px",
    margin: "auto",
    background: "rgba(255, 255, 200, 0.6)",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.4)",
    position: "absolute",
    bottom: "15px",
    right: "0px",
    transform: "rotate(-35deg)",
  },
};

const buttonStyles = {
  position: "absolute",
  bottom: "10px",
  left: "25%",
  width: "50%",
  margin: "1rem 0",
  marginTop: "2rem",
  backgroundColor: "#FFFDDE",
  color: "#333333",
  border: "none",
  borderRadius: "10px",
  padding: "0.5rem 2rem",
  boxShadow:
    "0px 0px 6px rgba(255,253,231,0.6), inset 0px 0px 6px rgba(255,253,231,0.6), 0px 0px 16px rgba(255,253,231,0.4), inset 0px 0px 16px rgba(255,253,231,0.4), 0px 0px 32px rgba(255,253,231,0.2), inset 0px 0px 32px rgba(255,253,231,0.2)",
  "&.Mui-disabled": {
    backgroundColor: "#666",
    color: "#333333",
    border: "none",
    cursor: "not-allowed",
    boxShadow: "none",
  },
};

const StyledBox = styled(Box)(({ theme }) => ({
  ...boxStyles,
  height: "800px",
  transition: "height 0.3s ease",
  [theme.breakpoints.down("md")]: {
    width: "90%",
    height: "620px",
  },
}));

const StyledCardContent = styled(CardContent)(() => cardContentStyles);

function ReservationButton({ complete, onClick }) {
  return (
    <Button
      sx={buttonStyles}
      onClick={onClick}
      variant="contained"
      color="primary"
      disabled={complete}
    >
      {complete ? "Complet" : "Réserver"}
    </Button>
  );
}

function ArticleEvent({ id, image, date, title, complete }) {
  const [open, setOpen] = useState(false);

  return (
    <StyledBox>
      <Typography variant="h5">{getFrFormat(date)}</Typography>
      <Typography variant="h4">{title}</Typography>
      <StyledCardContent>
        {image && (
          <CardMedia
            component="img"
            image={image.url}
            alt={`Affiche de l'évènement ${title}`}
            sx={{
              margin: "auto",
              objectFit: "contain",
              width: "95%",
              height: "95%",
            }}
          />
        )}
      </StyledCardContent>
      {complete && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%) rotate(-45deg)",
            width: "90%",
            backgroundColor: "#FFFDDE",
            backdropFilter: "blur(5px)",
            padding: "1rem",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              color: "#e73158",
            }}
          >
            complet
          </Typography>
        </Box>
      )}

      <Box sx={{ position: "relative", minHeight: "5rem" }} />
      {!complete && !isToLate(date) && (
        <ReservationButton complete={complete} onClick={() => setOpen(true)} />
      )}
      {(complete || isToLate(date)) && (
        <Box
          sx={{
            position: "absolute",
            bottom: "0%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            background: "rgba(0, 0, 0, 0.6)",
            backdropFilter: "blur(5px)",
            padding: "1rem",
            textAlign: "center",
          }}
        >
          <Typography variant="body1" sx={{ color: "#fffde7" }}>
            Les réservations ne sont plus possible pour cette date, mais vous
            pouvez toujours venir sans réservation.
          </Typography>
        </Box>
      )}
      <BookingForm
        open={open}
        onClose={() => setOpen(false)}
        eventDate={date}
        eventTitle={title}
        eventId={id}
      />
    </StyledBox>
  );
}

export default ArticleEvent;
