import moment from "moment-timezone";
import "moment/locale/fr";

moment.locale("fr");
moment.tz.setDefault("Europe/Paris");
/**
 * Creates a moment object from a given date string or the current date.
 * @param {string} [dateString] - A string representing a date in 'YYYY-MM-DD' format. If not provided, the current date is used.
 * @returns {moment.Moment} A moment object representing the specified date or the current date.
 */
export function getDate(dateString) {
  const date = dateString || `${moment().format("YYYY-MM-DD")}`;
  return moment(date);
}

/**
 * Retrieves the ISO week number for a given date.
 * @param {string} dateString - A string representing a date. The format should be compatible with moment.js parsing.
 * @returns {number} The ISO week number of the year for the given date.
 */
export function getWeek(dateString) {
  const week = moment(dateString).isoWeek();
  return week;
}

/**
 * Formats a given date string into a French long date format.
 * @param {string} dateString - A string representing a date. The format should be compatible with moment.js parsing.
 * @returns {string} A formatted string representing the date in French, with the day of the week and month in uppercase,
 *                   and the day of the month as a number. Format: "DAYOFWEEK DAY MONTH" (e.g., "LUNDI 15 JANVIER").
 */
export function getFrFormat(dateString) {
  const local = moment(dateString);
  return `${local.format("dddd").toUpperCase()} ${local.format("D")} ${local
    .format("MMMM")
    .toUpperCase()}`;
}

/**
 * Formats a given date string into a shortened French date format.
 * @param {string} dateString - A string representing a date. The format should be compatible with moment.js parsing.
 * @returns {string} A formatted string representing the date in French, with the day of the week and abbreviated month in uppercase,
 *                   and the day of the month as a number. Format: "DAYOFWEEK DAY ABBR_MONTH" (e.g., "LUNDI 15 JAN").
 */
export function getFrFormatShort(dateString) {
  const local = moment(dateString);
  return `${local.format("dddd").toUpperCase()} ${local.format("D")} ${local
    .format("MMM")
    .toUpperCase()}`;
}

/**
 * Formats a given date string into a French time format.
 * @param {string} dateString - A string representing a date and time. The format should be compatible with moment.js parsing.
 * @returns {string} A formatted string representing the time in French locale format (e.g., "14:30").
 */
export function getFrTimeFormat(dateString) {
  return `${moment(dateString).format("LT")}`;
}

/**
 * Checks if a given date is the same as or after the current date.
 * @param {string} dateString - A string representing a date. The format should be compatible with moment.js parsing.
 * @returns {boolean} Returns true if the given date is the same as or after the current date, false otherwise.
 */
export function isAfter(dateString) {
  return moment(dateString).isSameOrAfter(moment(), "day");
}

/**
 * Returns a formatted time string based on the given date.
 * If the date falls on a Sunday, the time is set to 12:00:00.
 * Otherwise, the time is set to 20:00:00.
 *
 * @param {Date|string|number} date - The date to format. Can be a Date object, a string, or a timestamp.
 * @returns {string} The formatted date string in "YYYY-MM-DD HH:mm:ss" format.
 */
export const getTime = (date) => {
  if (moment(date).day() === 0) {
    return moment(date)
      .hour(12)
      .minute(0)
      .second(0)
      .local("fr")
      .format("YYYY-MM-DD HH:mm:ss");
  }
  return moment(date)
    .hour(20)
    .minute(0)
    .second(0)
    .local("fr")
    .format("YYYY-MM-DD HH:mm:ss");
};

/**
 * Returns a string indicating the availability of a table based on the given date.
 *
 * @param {Date|string|number} date - The date to check the table availability for. Can be a Date object, a string, or a timestamp.
 * @returns {string} A message indicating the table availability time.
 */
export const getTableHour = (date) => {
  if (moment(date).day() === 0) {
    return "*Votre table sera disponible à partir de 12h00 jusqu'à 14h00";
  }
  return "*Votre table sera disponible à partir de 19h00 jusqu'à 20h30";
};

/**
 * Checks if the given reservation date is too late.
 * A date is considered too late if it is the same day as the current date and the current time is 19:00 or later.
 *
 * @param {string|Date|moment.Moment} dateResa - The reservation date to check.
 * @returns {boolean} - Returns true if the reservation date is too late, otherwise false.
 */
export const isToLate = (dateResa) => {
  console.log(dateResa);
  if (moment(dateResa).isSame(moment(), "day") && moment().hour() >= 19) {
    return true;
  }
  return false;
};
